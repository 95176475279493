<template>
  <div class="pricing-card flex-grow-1">
    <div class="text-t4 font-weight-bold">{{name}}</div>
    <div class="py-6">
      <div class="text-t2">
        <i18n-n :value="price" format="currency" :locale="currencyLocale">
          <template #decimal></template>
          <template #fraction></template>
        </i18n-n>
      </div>
      <div class="text-t6 paragraph--text">per month</div>
    </div>

    <div class="pb-6 d-flex justify-center">
      <ul class="feature-list text-t6 font-weight-bold text-left">
        <li class="feature-list__item" :class="feature.isConfirmed ? 'primary--text' : 'disable--text'"
            v-for="(feature, idx) in features" :key="idx">
          {{ $t(feature.label) }}
        </li>
      </ul>
    </div>

    <div class="text-t6 paragraph--text">
      {{description}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PricingCard',
  props: {
    name: {
      type: String,
      default: 'Product Name',
    },
    price: {
      type: [Number, String],
      default: 1000,
    },
    currencyLocale: {
      type: String,
      default: 'en-US',
    },
    description: {
      type: String,
      default: 'Product Summary',
    },
    features: {
      type: Array,
      default: () => ([
        {
          label: 'Product Feature 01',
          isConfirmed: true,
        },
        {
          label: 'Product Feature 02',
          isConfirmed: false,
        },
        {
          label: 'Product Feature 03',
          isConfirmed: true,
        }
      ]),
    },
  },
}
</script>

<style lang="scss" scoped>
.pricing-card {
  border-radius: 12px;
  padding: 36px 20px;

  border: 4px solid #ccc;
}

.feature-list {
  list-style: none;
  padding: 0;

  &__item {
    $item-padding: 5px;

    padding: $item-padding $item-padding $item-padding calc(1em + 12px + #{$item-padding});
    position: relative;

    &:before {
      content: '\f00c';
      font-family: 'Font Awesome 5 Pro';

      display: block;
      position: absolute;
      top: 0;
      left: 0;
      padding: 5px;
    }
  }
}
</style>
