<template>
  <div>
    <section-wrap>
      <div class="text-t2 text-center" data-aos="fade-down">Pricing</div>
      <markian-card-group :data="products" md="4">
        <template #default="product">
          <pricing-card :name="$t(product.name)" :description="$t(product.description)"
                        :price="product.price"
                        data-aos="zoom-out"
                        :data-aos-delay="$vuetify.breakpoint.mdAndUp ? product.idx*250 : 0"
                        :features="product.features" />
        </template>
      </markian-card-group>
    </section-wrap>
  </div>
</template>

<script>
import SectionWrap from '@/layouts/ServiceIntro/Sections/SectionWrap';
import MarkianCardGroup from '@/components/MarkianCardGroup';
import PricingCard from '@/components/PricingCard';

export default {
  name: 'PricingView',
  components: {
    SectionWrap,
    MarkianCardGroup,
    PricingCard,
  },
  data: () => ({
    currencyLocale: 'en-US',
    products: [
      {
        idx: 0,
        name: 'basicPlan',
        price: 299,
        description: 'basicPlanDesc',
        features: [
          {
            label: 'recomProvide',
            isConfirmed: true,
          },
          {
            label: 'searchProvide',
            isConfirmed: false,
          },
          {
            label: 'audienceProvide',
            isConfirmed: false,
          },
          {
            label: 'insightProvide',
            isConfirmed: true,
          },
        ],
      },
      {
        idx: 1,
        name: 'standardPlan',
        price: 899,
        description: 'standardPlanDesc',
        features: [
          {
            label: 'recomProvide',
            isConfirmed: true,
          },
          {
            label: 'searchProvide',
            isConfirmed: true,
          },
          {
            label: 'audienceProvide',
            isConfirmed: false,
          },
          {
            label: 'insightProvide',
            isConfirmed: true,
          },
        ],
      },
      {
        idx: 2,
        name: 'premiumPlan',
        price: 1499,
        description: 'premiumPlanDesc',
        features: [
          {
            label: 'recomProvide',
            isConfirmed: true,
          },
          {
            label: 'searchProvide',
            isConfirmed: true,
          },
          {
            label: 'audienceProvide',
            isConfirmed: true,
          },
          {
            label: 'insightProvide',
            isConfirmed: true,
          },
        ],
      },
    ],
  }),
  methods: {
    i18nView() {
      console.log('i18n', this.$i18n);
    },
  },
}
</script>
